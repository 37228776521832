import Vue from 'vue'
import page from 'page'
// import App from './App.vue'
import './quasar'
import './clipboard'
import './qrcode'
import routes from './routes'
// import loaddb from './api/loaddb'

(async () => {
  // window.dbReady = false;
  // const kobo = await loaddb.get('/KoboReader.sqlite')

  Vue.config.productionTip = false
  
  const app = new Vue({
    el: '#app',
    data: {
      kobo: null,
      ViewComponent: { render: h => h('div', 'loading...') }
      // ViewComponent: () => import('./App.vue')
    },
    render (h) { return h(this.ViewComponent) }
  })
  
  Object.keys(routes).forEach(route => {
    const Component = () => import('./pages/' + routes[route] + '.vue')
    console.debug('==> ', routes[route])
    page(route, () => app.ViewComponent = Component)
  })
  page('*', () => app.ViewComponent = () => import('./pages/404.vue'))
  page({
    hashbang: true
  })

  // const kobo = await loaddb.get('/KoboReader.sqlite')
  // app.db = kobo
  // window.dbReady = true;
})();