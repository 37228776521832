import Vue from 'vue'

import './styles/quasar.sass'
import '@quasar/extras/material-icons/material-icons.css'
import { Quasar, Notify, QDialog, ClosePopup } from 'quasar'

Vue.use(Quasar, {
  config: {
    brand: {
      primary: '#bf0000',
      secondary: '#002896',
      accent: '#f57700',

      dark: '#666666',

      positive: '#008020',
      negative: '#ef4f00',
      info: '#00a3e7',
      warning: '#ffcc00'
    },
    notify: {
      position: 'top-right' ,
      color: 'dark',
      timeout: '1000',
      // closeBtn: 'dismiss',
      icon: 'done'
    }
  },
  components: { 
    QDialog,
    /* not needed if importStrategy is not 'manual' */ 
  },
  directives: { 
    ClosePopup
    /* not needed if importStrategy is not 'manual' */ },
  plugins: {
    Notify
  }
 })